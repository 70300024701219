import { z } from 'zod';

export interface LoginBody {
  username: string;
  password: string;
  from?: string;
}

export const loginSchema = z.object({
  from: z.string(),
  username: z.string().min(1, 'Username is required'),
  password: z.string().min(1, 'Password is required'),
});
