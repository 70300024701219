import * as React from 'react';
import {
  type LoaderFunctionArgs,
  Navigate,
  Outlet,
  createBrowserRouter,
  redirect,
} from 'react-router-dom';

import { LoadingFlexBox } from '@/components/Common/CenteredFlexBox';
import FullPageSpinner from '@/components/Spinner';
import Login, { loginLoader } from '@/pages/Login';

import { authStore } from '@/features/auth/authStore';
import { debugLog } from '@/lib/utility';

const Workbooks = React.lazy(async () => import('@/pages/Workbooks'));
const WorkbookLayout = React.lazy(async () => import('@/pages/WorkbookLayout'));
const Workbook = React.lazy(async () => import('@/pages/Workbook'));
const ErrorPage = React.lazy(async () => import('@/pages/Error'));
const Home = React.lazy(async () => import('@/pages/Home'));

/**
 * If the user is not logged in and tries to access a protected route, we
 * redirect them to `/login` with a `from` parameter that allows login to
 * redirect back to this page upon successful authentication
 *
 * @param request
 */
async function protectedLoader({ request }: LoaderFunctionArgs) {
  return new Promise((resolve, _reject) => {
    debugLog('Determining how to handle protected route');
    const isAuthenticated = authStore.isAuthenticated;
    if (isAuthenticated) {
      resolve(null);
      return;
    }

    const params = new URLSearchParams();
    params.set('from', new URL(request.url).pathname);
    resolve(redirect(`/login?${params.toString()}`));
  });
}

const Wrapper: React.FC = () => {
  return (
    <div>
      <FullPageSpinner />
      <Outlet />
    </div>
  );
};

export const createRouter = () =>
  createBrowserRouter([
    {
      id: 'root',
      path: '/',
      Component: Wrapper,
      errorElement: (
        <React.Suspense fallback={<LoadingFlexBox />}>
          <ErrorPage />
        </React.Suspense>
      ),

      children: [
        {
          index: true,
          element: <Navigate to="/workbooks" replace />,
          // Component: Home,
        },
        {
          path: 'login',
          loader: loginLoader,
          Component: Login,
        },
        {
          path: 'workbooks',
          loader: protectedLoader,
          element: (
            <React.Suspense fallback={<LoadingFlexBox />}>
              <Home />
            </React.Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <React.Suspense fallback={<LoadingFlexBox />}>
                  <Workbooks />
                </React.Suspense>
              ),
            },
            {
              path: ':workbookId',
              element: (
                <React.Suspense fallback={<LoadingFlexBox />}>
                  <WorkbookLayout />
                </React.Suspense>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <React.Suspense fallback={<LoadingFlexBox />}>
                      <Workbook />
                    </React.Suspense>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/logout',
      loader: () => {
        debugLog('Signing user out');
        authStore.signOut();
        return null;
      },
      element: <Navigate to="/" replace />,
    },
  ]);
