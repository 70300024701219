import { zodResolver } from '@hookform/resolvers/zod';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Form, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { type LoginBody, loginSchema } from './LoginValidation';

import styles from './Login.module.scss';

import { authStore } from '@/features/auth/authStore';

const Login = () => {
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const from = searchParams.get('from') ?? '';

  const { register, handleSubmit } = useForm<LoginBody>({
    resolver: zodResolver(loginSchema),
    defaultValues: { from },
  });

  useEffect(() => {
    if (error != null) {
      toast.error('Error logging in', { theme: 'colored' });
    }
  }, [error]);

  const onSubmit: SubmitHandler<LoginBody> = async (data) => {
    if (/^good$/gi.test(data.password)) {
      authStore.setIsAuthenticated(true, data.username);
      navigate(data.from ?? '/');
    } else {
      setError('Invalid password');
    }
  };

  return (
    <div className={styles.container}>
      <h1>Login</h1>
      <Form method="post" onSubmit={handleSubmit(onSubmit)}>
        <input {...register('from')} type="hidden" />
        <input
          {...register('username')}
          type="text"
          autoComplete="username"
          placeholder="username"
          className="field"
        />
        <input
          {...register('password')}
          type="password"
          autoComplete="current-password"
          placeholder="password"
          className="field"
        />
        <input type="submit" value="login" className="btn" />
      </Form>
      <div className="pass-link">
        <a href="#foo">Lost your password?</a>
      </div>
      <Container sx={{ mt: '24px' }}>
        <Typography
          textAlign="center"
          whiteSpace="nowrap"
          color="text.secondary"
          variant="body2"
        >
          {import.meta.env.VITE_BUILD_TAG}
        </Typography>
      </Container>
    </div>
  );
};

export default Login;
