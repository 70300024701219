import { Box, CircularProgress, Typography } from '@mui/material';
import * as React from 'react';
import type { FC, ReactNode } from 'react';

export interface CenteredFledBoxProps {
  children: ReactNode;
  color?: string;
}

export const CenteredFlexBox: FC<CenteredFledBoxProps> = ({
  color = 'text.primary',
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // width: '100%',
        // height: '100%',
        flexGrow: '1',
        // padding: '0 10% 25% 10%',
        gap: '8px',
      }}
    >
      <Typography sx={{ textAlign: 'center' }} variant="h5" color={color}>
        {children}
      </Typography>
    </Box>
  );
};

export const ErrorFlexBox: FC<CenteredFledBoxProps> = ({
  color = 'error.main',
  children,
}) => {
  return <CenteredFlexBox color={color}>{children}</CenteredFlexBox>;
};

export const LoadingFlexBox: FC = () => {
  return (
    <CenteredFlexBox>
      <CircularProgress />
    </CenteredFlexBox>
  );
};
