import * as Sentry from '@sentry/react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { debugLog } from '@/lib/utility';
import type { SetupWorker } from 'msw/browser';

interface MyModule {
  worker: SetupWorker;
}

const mock = async (mockModule: MyModule) => {
  const activeWhen = ['development', 'production'];
  const activeNow = activeWhen.includes(process.env.NODE_ENV ?? '');

  if (!activeNow) return;

  const { worker } = mockModule;

  await worker.start({
    onUnhandledRequest: 'bypass',
  });
};

const mockMsw = async () => {
  debugLog(`main: initializing full mocks in mode: ${process.env.NODE_ENV}`);
  const module = await import('./mocks/browser');
  await mock(module);
};

const mockMinimal = async () => {
  debugLog(`main: initializing minimal mocks in mode: ${process.env.NODE_ENV}`);
  const module = await import('./mocks/browser-minimal');
  await mock(module);
};

const sentry = () => {
  Sentry.init({
    environment: location.hostname,
    enabled: location.hostname !== 'localhost',
    dsn: 'https://33ba9672aafb31c7bded7a1c0c7d4653@o4506300439396352.ingest.us.sentry.io/4506300542484480',
    integrations: [Sentry.browserTracingIntegration()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    tracePropagationTargets: [
      /^https:\/\/flexpavement\.dev/,
      /^https:\/\/coc\.flexpavement\.dev/,
    ],

    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
};

// noinspection JSUnusedLocalSymbols
const _mainMock = () => {
  debugLog('main: beginning bootstrap process');
  mockMsw()
    .then(() => {
      debugLog('main: mocking promise has completed');
    })
    .then(() => {
      debugLog('main: React initialization has begun');
      const container = document.getElementById('root') as HTMLElement;
      const root = createRoot(container);

      root.render(<App />);
      /*
            root.render(
              <React.StrictMode>
                <App />
              </React.StrictMode>,
            );
      */
    })
    .then(() => {
      debugLog('main: React initialization is complete');
    })
    .catch((e: unknown) => {
      if (e instanceof Error) {
        debugLog(`main: an error has occurred: ${e.message ?? 'unknown'}`);
      }
    })
    .finally(() => {
      debugLog('main: bootstrap is complete');
    });
};

const main = () => {
  debugLog('main: beginning bootstrap process');
  sentry();
  mockMinimal()
    .then(() => {
      debugLog('main: mocking promise has completed');
    })
    .then(() => {
      debugLog('main: React initialization has begun');
      const container = document.getElementById('root') as HTMLElement;
      const root = createRoot(container);

      root.render(<App />);
      /*
            root.render(
              <React.StrictMode>
                <App />
              </React.StrictMode>,
            );
      */
    })
    .then(() => {
      debugLog('main: React initialization is complete');
    })
    .catch((e: unknown) => {
      if (e instanceof Error) {
        debugLog(`main: an error has occurred: ${e.message ?? 'unknown'}`);
      }
    })
    .finally(() => {
      debugLog('main: bootstrap is complete');
    });
};

main();
